import React from 'react';
import { Link } from 'gatsby';
import PostTags from './PostTags';

function PostListing({ postEdges }) {
  const postList = [];
  postEdges.forEach((postEdge) => {
    postList.push({
      path: postEdge.node.fields.slug,
      tags: postEdge.node.frontmatter.tags,
      cover: postEdge.node.frontmatter.cover,
      title: postEdge.node.frontmatter.title,
      summary: postEdge.node.frontmatter.summary,
      date: postEdge.node.fields.date,
      excerpt: postEdge.node.excerpt,
      timeToRead: postEdge.node.timeToRead,
    });
  });

  return (
    <>
      {
        /* Your post list here. */
        postList.map((post) => (
          <div
            className='bg-white rounded-xl shadow-md flex items-center overflow-hidden group cursor-pointer'
            key={post.path}
          >
            <Link className='flex flex-row' to={post.path}>
              <img className='h-auto w-52' src={post.cover} alt='' />
            </Link>
            <div className='flex flex-col p-6'>
              <Link className='flex flex-row' to={post.path}>
                <h1 className='text-xl text-bold mb-1.5 no-underline group-hover:underline'>{post.title}</h1>
              </Link>
              <Link className='flex flex-row' to={post.path}>
                <p className='text-base'>{post.summary}</p>
              </Link>
              <PostTags tags={post.tags} />
            </div>
          </div>
        ))
      }
    </>
  );
}

export default PostListing;
